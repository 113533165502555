import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class ThePlayerLounge extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 p-0">
                <div className="title p-0 section-margin">
                  Fan Engagement Platform for US Collegiate Athletics
                </div>
                <div className="subtitle section-margin">
                  From Athletes, For Fans: redefining the college sports experience
                </div>
                <div className="client-title section-margin">
                  CLIENT |{' '}
                  <img
                    src={
                      require('../../assets/images/portfolio/projects/logos/the-player-lounge.png')
                        .default
                    }
                  />
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Unlock monetization opportunities for college athletes by creating a fan
                      engagement platform for college football fans in the US.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed a robust platform containing interactive spaces, a gamified reward
                      system, and a scalable architecture that could support more advanced features
                      planned for the future.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    {/* unordered list here */}
                    <ul className="desc">
                      <li>Launched the Go-to-Market MVP in 2 months.</li>
                      <li>Raised $2.5M in funding till date.</li>
                      <li>4 colleges signed up till date.</li>
                    </ul>
                  </div>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://drive.google.com/file/d/11QnwGeJGT-GE6zrF-hdiXJo0H08yV0TC/preview"
                    width="640"
                    height="480"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin section-margin">
                    <div className="subheading-big">What was the client looking for?</div>
                    <p className="description">
                      College football is more than a sport in the United States—it's a cultural
                      phenomenon with massive viewership and fervent fan engagement. However,
                      college athletes previously had no way to monetize their fame due to
                      restrictive regulations on their name, image, and likeness (NIL).
                    </p>
                    <br />
                    <p className="description">
                      The Players' Lounge (TPL) was founded by an NFL athlete who intimately
                      understood the challenges faced by student-athletes. He saw an opportunity
                      when NIL rules changed, envisioning a platform that gamified the fan
                      experience, offering sports news, fan interactions, and exclusive rewards like
                      athlete meet-and-greets.
                    </p>
                    <br />
                    <p className="description">
                      Stuart Bush, TPL's CPTO, also knew that fan engagement was key to helping
                      athletes build personal brands. He partnered with Tintash for our expertise in
                      creating interactive, gamified experiences.
                    </p>
                    <br />
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      Tintash has been the best dev partner I have hired in my two-decade plus
                      career.
                    </p>
                    <p className="desc pt-2">
                      Tintash is a critical partner for our startup as we build our platform. In
                      addition of bringing our vision to life, the team’s deep knowledge is evident
                      in their innovative solutions and modernization suggestions - from system
                      architecture to UI/UX improvements. We would not have achieved our time to
                      market and growth without Tintash.
                    </p>
                    <div className="d-flex my-3">
                      <img
                        src={
                          require('../../assets/images/the-player-lounge/tpl-client-image.png')
                            .default
                        }
                        alt="Stuart Bush"
                        className="rounded-circle"
                      />

                      <div className="p-3 m-0 mt-auto mb-auto">
                        <h2 className="review-name m-0" style={{fontWeight: 'bold'}}>
                          Stuart Bush
                        </h2>
                        <p className="review-name mt-0">
                          Chief Product Officer - The Players’ Lounge
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Right at the outset, we faced two key challenges. First, building a scalable
                      platform that could launch with a single college team and expand seamlessly to
                      multiple teams. Second, we had to move quickly and launch before the upcoming
                      football season to capture peak fan interest.
                    </p>
                    <br />
                    <br />
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Phase 1: Rapid Beta Development for the University of Georgia
                    </p>
                    <p className="description">
                      The target for Phase 1 was to build a simple blog site exclusively for the
                      University of Georgia. This would help attract TPL's existing customers, who
                      were all affiliated with the University of Georgia, and familiarize them with
                      the upcoming platform, gradually introducing them to the more advanced
                      features planned for later phases.
                    </p>
                    <br />
                    <p className="description">
                      Our team collaborated closely with the CPTO to align the platform's features
                      with the needs and preferences of their existing customers. We worked towards
                      focused content delivery, creating a user-friendly blog platform that
                      delivered the latest news and updates about Georgia sports.
                    </p>
                    <br />
                    <p className="description">
                      The beta site was launched within the ambitious one-month deadline. It enabled
                      TPL’s existing core user base, University of Georgia folks, to start engaging
                      with the platform early, building anticipation for future feature releases. It
                      also provided a live environment to gather user data and feedback, helping
                      validate TPL’s core proposition.
                    </p>
                    <br />
                    <p className="description">
                      Early user feedback helped refine our product roadmap for subsequent phases.
                      We had established a scalable codebase and architecture. Learnings from the
                      beta launch prepared us for the challenges of expanding to other college teams
                      and supporting the more complex features next in line.
                    </p>
                    <div className="col-12 p-0 section-margin">
                      <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-12 my-4">
                          <img
                            className="w-100"
                            src={require('../../assets/images/the-player-lounge/tpl-1.png').default}
                            alt="tpl"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Phase 2: Feature Expansion and Scalability
                    </p>
                    <p className="description">
                      Following the successful launch of the beta site in Phase 1, our main
                      objectives in Phase 2 were expanding the platform and increasing user
                      engagement.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-6 p-0 d-flex ">
                      <div
                        className="image-description text-left mb-0"
                        style={{lineHeight: '150%'}}
                      >
                        <p className="description mb-2" style={{fontWeight: 'bold'}}>
                          a. Expansion to Four Colleges
                        </p>
                        <p className="description">
                          We expanded the platform to include three more colleges, adding localized
                          news and community spaces tailored to each fan base.
                        </p>
                        <br />
                        <p className="description mb-2" style={{fontWeight: 'bold'}}>
                          b. Reddit-Style Message Board
                        </p>
                        <p className="description">
                          We introduced community forums to facilitate discussions and banter among
                          rival fans. Users could create posts, comment, and upvote or downvote
                          content. This boosted user engagement and time spent on the platform,
                          fostering greater interaction and a vibrant online community.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 my-2 d-flex justify-content-center">
                      <img
                        className="wrap-image"
                        src={require('../../assets/images/the-player-lounge/tplone.png').default}
                        alt="tpl"
                        style={{width: '75%', height: 'auto'}}
                      />
                    </div>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex my-3">
                    <div className="col-lg-6 p-0 d-flex ">
                      <div
                        className="image-description text-left mb-0"
                        style={{lineHeight: '150%'}}
                      >
                        <p className="description mb-2" style={{fontWeight: 'bold'}}>
                          c. Gamified Points System
                        </p>
                        <p className="description">
                          We implemented a gamified reward system to encourage greater user activity
                          on the platform. Users could earn points for different engagements
                          (posting and sharing content, commenting on posts) which could then be
                          redeemed for exclusive perks such as player jerseys and VIP tickets to
                          events. To make the system fair and cheat-proof, we developed algorithms
                          to accurately track user activity and implemented security measures to
                          prevent exploitation. This points system helped ensure consistent user
                          engagement and loyalty to the platform.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 my-2 d-flex justify-content-center">
                      <img
                        className="wrap-image"
                        src={require('../../assets/images/the-player-lounge/tpltwo.png').default}
                        alt="tpl"
                        style={{width: '75%', height: 'auto'}}
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 my-3">
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      d. Scalable Architecture
                    </p>
                    <p className="description">
                      As mentioned above, one of our key objectives was to build a scalable
                      platform, one that could handle growth (increased user load) and support
                      advanced features TPL had envisioned for the future, such as athlete profiles
                      and live streaming. Towards this end, we implemented microservices and
                      micro-frontends that together provided a robust platform foundation.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex my-3">
                    <div className="col-lg-6 p-0 d-flex ">
                      <div
                        className="image-description text-left mb-0"
                        style={{lineHeight: '150%'}}
                      >
                        <br />
                        <p className="description">
                          The microservices architecture enabled a scalable and maintainable backend
                          that allowed independent deployment of services. This entailed breaking
                          down the backend into modular services, each handling a specific
                          functionality such as user authentication, user-generated content
                          management, or the gamification system.
                        </p>
                        <br />
                        <p className="description">
                          Similarly, implementing micro-frontends enabled the different frontend
                          teams to work on various features simultaneously without having to deal
                          with code conflicts. We divided the frontend into separate modules that
                          could be developed, tested, and deployed independently.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 my-2 d-flex justify-content-center">
                      <img
                        className="wrap-image"
                        src={require('../../assets/images/the-player-lounge/tplthree.png').default}
                        alt="tpl"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 my-3">
                    <p className="description">
                      A key challenge for our team working on this project was adaptability,
                      necessitated by the frequently changing scope and priorities. In order to
                      deliver on the client’s evolving vision for the platform, we employed flexible
                      agile development methodologies.
                    </p>
                  </div>
                  <div className="col-12 p-0 my-3">
                    <p className="description">
                      Another challenge was the ambitious timeline of this project. With an
                      increased team size, optimized workflows, and the occasional overtime work, we
                      managed to successfully launch the enhanced platform in time for the new
                      football season. The client was satisfied with the new platform’s capabilities
                      and highly rated our team’s ability to deliver under pressure.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">What does the final product look like?</div>
                    <p className="description">
                      The final platform was a dynamic, interactive hub for college sports fans,
                      featuring:
                    </p>
                    <br />
                    <ul>
                      <li className="description mb-2">
                        <span className="font-weight-bold">Real-Time News Updates:</span>{' '}
                        comprehensive coverage of college sports events.
                      </li>
                      <li className="description mb-2">
                        <span className="font-weight-bold">Community Forums:</span> spaces for fans
                        to interact and discuss their favorite teams.
                      </li>
                      <li className="description mb-2">
                        <span className="font-weight-bold">Gamification Elements:</span> point
                        systems and rewards to incentivize engagement
                      </li>
                      <li className="description">
                        <span className="font-weight-bold">Scalable Design:</span> ready
                        infrastructure for adding new features and expanding to more teams.
                      </li>
                    </ul>
                    <p className="description">
                      The platform’s timely market entry with four college teams right before the
                      new football season helped TPL with its user acquisition strategy. With its
                      tailored news content and community spaces, the platform saw increased user
                      engagement. The message boards were highly active, the gamified experience
                      resulting in increased participation rates as the users were motivated to earn
                      and redeem points.
                    </p>
                    <br />
                    <p className="description">
                      Despite the increase in platform traffic, it maintained fast load times and a
                      smooth user experience. This initial success positioned our client as an
                      innovative leader in fan engagement platforms. The scalable architecture also
                      allows TPL to grow the platform by introducing more advanced features without
                      any major technical overhaul as it aims to unlock future monetization
                      opportunities for college athletes.
                    </p>
                    <br />
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-center flex-column">
                <div className="tin-text"> Created and Developed by</div>
                <div className="mb-5 mt-3">
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default ThePlayerLounge;
