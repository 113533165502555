import React, {Component} from 'react';
import Layout from '../../components/Shared/Layout';
import '../../assets/css/decorist.css';
import * as ReactGA from 'react-ga';
import ThePlayerLounge from '../../components/Portfolio/thePlayerLounge';

class TplPage extends Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio/ThePlayerLounge',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <div>
        <Layout headerPosition="fixed">
          <ThePlayerLounge />
        </Layout>
      </div>
    );
  }
}
export default TplPage;
